import React from 'react';

const Map = () => (
  <div className="mapouter">
    <div className="gmap_canvas">
      <iframe width="100%" height="550px" id="gmap_canvas"
        src="https://maps.google.com/maps?q=drosselweg%2018%2082152&t=&z=11&ie=UTF8&iwloc=&output=embed" frameBorder="0"
        scrolling="no" marginHeight="0" marginWidth="0"/>
    </div>
  </div>
);

export default Map;
