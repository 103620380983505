import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Map from '../components/map';
import Markdown from 'react-markdown';
import Img from 'gatsby-image';
const AtelierPage = ({ data: { allMarkdownRemark: { edges } } }) => {
  const [data] = edges;
  const { node: { frontmatter } } = data;
  console.debug(frontmatter);
  function textRender (props) {
    return <p className={'is-size-5  has-text-left-mobile'}>{props.children}</p>;
  }
  function renderLink (props) {
    return (<a href={props.href} target={'_blank'}>{props.children}</a>);
  }
  return (
    <Layout>
      <SEO title="Schittler Restaurierung - Das Atelier" description={'Das modern eingerichtete Atelier in Krailling bei München bietet die Möglichkeit zur restauratorischen Bearbeitung Ihrer Kunstwerke unter konservatorisch idealen Bedingungen.'}/>
      <section className="section">
        <div className={'columns'}>
          <div className={'column is-10 is-offset-1'}>
            {frontmatter.title &&
            <h1 className="title">{frontmatter.title}</h1>
            }
            {frontmatter.subtitle &&
            <h2 className="subtitle content">
              {frontmatter.subtitle}
            </h2>
            }
          </div>
        </div>
        <div className="columns is-multiline">
          <div className="column is-10 is-offset-1">
            <div className={'content is-size-5'}>
              <Markdown source={frontmatter.description} renderers={{ paragraph: textRender, link: renderLink }}/>
              <Link className="button is-light" to={'/contact'}>
                <span className="icon">
                  <i className="fas fa-envelope"/>
                </span>
                <strong>kontakt</strong>
              </Link>
            </div>
          </div>
          <div className="column is-10-desktop is-offset-1">
            <div className="columns is-multiline">
              {frontmatter.images
                .filter(({ image: img }) => Boolean(img))
                .map(({ image: { id, childImageSharp: { fluid } }, text, size }, i) =>
                  <div className={`column is-${size}`} key={id}>
                    <Img
                      fluid={fluid}
                      alt={text}
                      objectFit="contain"
                      style={{ height: '550px', maxHeight: 550 }}
                      imgStyle={{ height: '550px', minHeight: '550px', maxHeight: 550, objectFit: 'cover' }}
                      sizes={{ ...fluid, aspectRatio: fluid.aspectRatio }}
                    />
                  </div>
                )}
              <div className={`column is-${frontmatter.mapSize}`}>
                <Map/>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
};
export default AtelierPage;

export const pageQuery = graphql`
    query AtelierPage {
        allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "atelier" } } }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        subtitle
                        description
                        mapSize
                        images {
                            size
                            image {
                                id
                                childImageSharp {
                                    fluid(maxWidth: 800, quality: 75) {
                                        ...GatsbyImageSharpFluid_withWebp
                                        aspectRatio
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
